import Column from "@/Components/Backend/Grid/Column";
import Container from "@/Components/Backend/Grid/Container";
import Row from "@/Components/Backend/Grid/Row";
import WebpPicture from "@/Components/WebpPicture";
import { GalleryCarouselProps } from "@/types/components/front/blocks/galleryCarousel";
import { Navigation } from "swiper";
import { useCallback, useEffect, useRef, useState } from "react";
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";
import { Icon } from "@iconify/react/dist/iconify.js";
import BorderButton from "../../../Buttons/BorderButton";

import "./styles.scss";

import "swiper/css/navigation";
import "swiper/css";

import Text from "../../../Text";
import IconHeadingSection from "../../IconHeadingSection/IconHeadingSection";
import ClassicButton from "@/Components/Front/Buttons/ClassicButton";

const GalleryCarousel = ({ title, slides, button }: GalleryCarouselProps) => {
    const swiperRef = useRef<SwiperRef>(null);

    const [height, setHeight] = useState();

    const handlePrev = useCallback(() => {
        if (!swiperRef.current) return;
        swiperRef.current.swiper.slidePrev();

        updateNavPosition()
    }, []);

    const handleNext = useCallback(() => {
        if (!swiperRef.current) return;
        swiperRef.current.swiper.slideNext();

        updateNavPosition()
    }, []);

    const updateNavPosition = () => {
        if (swiperRef.current) {
            /* @ts-ignore */
            setHeight(swiperRef.current.swiper.el.querySelector('.swiper-slide-active picture>img').clientHeight + 16)
        }
    }

    const setInitNavPosition = () => {
        if (swiperRef.current) {
            /* @ts-ignore */
            setHeight(swiperRef.current.swiper.el.querySelector('.swiper-slide-active picture>img').clientHeight + 16)
        }
    }

    return (
        <div className="md:py-[52px] py-7 estate-slider">
            <IconHeadingSection className="mb-6">
                {title}
            </IconHeadingSection>
            <div className="relative">
                <Swiper
                    ref={swiperRef}
                    modules={[Navigation]}
                    loop={true}
                    spaceBetween={24}
                    centeredSlides={true}
                    slidesPerView={1}
                    onResize={setInitNavPosition}
                    // onInit={setInitNavPosition}
                    onSlideChange={setInitNavPosition}
                    onUpdate={setInitNavPosition}
                    breakpoints={{
                        768: {
                            slidesPerView: 1.5
                        },
                        992: {
                            slidesPerView: 1.7,
                        },
                        1280: {
                            slidesPerView: 2
                        }
                    }}
                >
                    {slides.map((slide, index) => (
                        <SwiperSlide key={index}>
                            <WebpPicture lazyLoad={false} src={slide.src} alt={slide.alt ? slide.alt : title} className="max-h-[570px]"/>
                            <div className=" max-w-[calc(100%_-_128px)] min-h-[56px]">
                                {slide.text && (
                                    <Text heading="h3" className="mt-4 md:ml-0 ml-3 slide-text">{slide.text}</Text>
                                )}
                                {slide.desc && (
                                    <Text className="mt-4 md:ml-0 ml-3 slide-text">{slide.desc}</Text>
                                )}
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>

                {/* Slider Buttons */}
                <Container>
                    <Row className="justify-center">
                        <Column xl={9} lg={9} md={9} className="flex justify-end gap-4">
                            <div className="absolute bottom-[height] z-10" style={{top: height}}>
                                <button
                                    id="prev-btn"
                                    onClick={handlePrev}
                                    className="p-2 border-i-indigo border-2 rounded-sm mr-4 hover:bg-i-blue transition-all ease-in-out duration-200"
                                    title="Poprzedni slajd"
                                >
                                    <Icon
                                        icon="ic:round-arrow-back-ios"
                                        width={24}
                                        height={24}
                                        className="text-i-navy"
                                    />
                                </button>
                                <button
                                    id="next-btn"
                                    onClick={handleNext}
                                    className="p-2 border-i-indigo border-2 rounded-sm hover:bg-i-blue transition-all ease-in-out duration-200"
                                    title="Kolejny slajd"
                                >
                                    <Icon
                                        icon="ic:round-arrow-forward-ios"
                                        width={24}
                                        height={24}
                                        className="text-i-navy"
                                    />
                                </button>
                            </div>
                        </Column>
                    </Row>
                </Container>
            </div>
            <Container>
                {button && (
                    <Row className="justify-center mt-8">
                        <Column xl={5} lg={5} md={4}>
                            <ClassicButton full linkProps={{ href: button.url }}>
                                {button.text}
                            </ClassicButton>
                        </Column>
                    </Row>
                )}
            </Container>
        </div>
    );
};

export default GalleryCarousel;
